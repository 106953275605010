import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import SearchIcon from '@material-ui/icons/Search'

import { Box, Button } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'

import ProductItem from "components/ProductItem"

import styles from "assets/jss/views/allProductViewStyle"

import FilterImg from "assets/img/filter.png"
import Product1 from "assets/img/product-1.png"
import Product2 from "assets/img/product-2.png"
import Product3 from "assets/img/product-3.png"
import Product4 from "assets/img/product-4.png"

const useStyles = makeStyles(styles)

const ProductSection = () => {

	const classes = useStyles() 

	const [ filter, setFilter ] = useState("All Categories")

	const filterOptions = [
		"All Categories",
		"Siphones",
		"Accesories",
		"Baloon",
		"Confetti",
		"Drinks",
		"Food",
		"Gas",
		"Drugs",
		"Drinks2",
		"Drinks3",
		"Filter 1",
		"Filter 2",
		"Filter 3",
		"Filter 4"
	]

	const products = [
		{
				image: Product1,
				title: "Fast Gas 580g N2O",
				price: "499 kr",
				reduce: "",
				sale: ""
		},
		{
				image: Product2,
				title: "Fast Gas 580g N2O",
				price: "499 kr",
				reduce: "",
				sale: ""
		},
		{
				image: Product3,
				title: "Fast Gas 3 Pack!",
				price: "999kr",
				reduce: "1299kr",
				sale: "50%"
		},
		{
				image: Product4,
				title: "Fast Gas 3 Pack!",
				price: "999kr",
				reduce: "1299kr",
				sale: "50%"
		},
		{
				image: Product1,
				title: "Fast Gas 6 Pack!",
				price: "1.799 kr",
				reduce: "2.499 kr",
				sale: "50%"
		},
		{
				image: Product1,
				title: "Fast Gas 580g N2O",
				price: "499 kr",
				reduce: "",
				sale: ""
		},
		{
				image: Product1,
				title: "Fast Gas 580g N2O",
				price: "499 kr",
				reduce: "",
				sale: ""
		},
		{
				image: Product2,
				title: "Fast Gas 580g N2O",
				price: "499 kr",
				reduce: "",
				sale: ""
		}
	]
  return (
		<Box className={classes.productSection}>
			<Container maxWidth="xl">
				<Grid container spacing={3}>
					<Grid item xs={12} className={classes.titleSection}>
						<Typography variant="h3" fontWeight={700} align="center">
							ALL PRODUCT
						</Typography>
						<Typography variant="body1" align="center">
							Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aliquet.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Box className={classes.searchFormWrapper}>
							<FormControl fullWidth className={classes.searchForm} variant="outlined">								
								<OutlinedInput
									placeholder="Search product"
									endAdornment={<InputAdornment position="start"><Button className={classes.searchBtn}><SearchIcon /></Button></InputAdornment>}								
								/>
							</FormControl>
						</Box>
						<Box className={classes.filterSection}>
							<Box className={classes.filterList}>
								<Button className={classes.filterBtn} startIcon={<img src={FilterImg} alt="Filter" />}>
									Filter
								</Button>
								{
									filterOptions.map((option, index) => (
										<Button 
											className={classNames(classes.filterBtn, {[classes.active]: option === filter})} 
											key={index}
											onClick={() => setFilter(option) }
										>
											{ option }
										</Button>
									))
								}
							</Box>							
						</Box>
					</Grid>
					{
						products.map((productItem, index) => (
							<Grid item lg={3} md={3} sm={6} xs={6} key={index}>
									<ProductItem product={productItem}/>
							</Grid> 
						))
					}
				</Grid>
				<Grid item xs={12}>
					<Box display="flex" justifyContent="center" className={classes.pagination}>
						<Stack spacing={2}>
							<Pagination count={3} variant="outlined" shape="rounded" />
						</Stack>
					</Box>
				</Grid>
			</Container>
		</Box>
	)
}

export default ProductSection
