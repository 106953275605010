const textFieldStyle = (theme) => ({
  label: {
    color: "white",
    fontSize: "14px",
    position: "relative",
    marginBottom: "4px",
    "& span": {
      color: "#FF4B4B"
    }
  },
  textField: {
    "& .MuiInputBase-root": {
      color: "white",
      fontSize: "14px",
      "& fieldset": {
        border: "0"
      }
    }
  }
})

export default textFieldStyle
