import React, {Fragment} from 'react'
import { makeStyles } from '@material-ui/core/styles'

import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

import styles from "assets/jss/components/breadcrumbStyle"

const useStyles = makeStyles(styles)

const Breadcrumb = (props) => {

	const classes = useStyles() 

	const { breadcrumb } = props

	return (
		<Box display="flex" className={classes.breadcrumb}>
			{
				breadcrumb.map((item, index) => (
					<Fragment key={index}>
						<Typography variant="body1">
							{item}
						</Typography>
						{
							(index !== breadcrumb.length - 1) && (
								<ChevronRightIcon />
							)
						}						
					</Fragment>					
				))
			}		
		</Box>
	)
}

export default Breadcrumb
