import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Button from 'components/CustomForms/Button'

import ProductItem from "components/ProductItem"

import styles from "assets/jss/views/landingViewStyle"

import Product1 from "assets/img/product-1.png"
import Product2 from "assets/img/product-2.png"
import Product3 from "assets/img/product-3.png"
import Product4 from "assets/img/product-4.png"

const useStyles = makeStyles(styles)

const AllProductsSection = () => {

    const classes = useStyles()  

    const products = [
        {
            image: Product1,
            title: "Fast Gas 580g N2O",
            price: "499 kr",
            reduce: "",
            sale: ""
        },
        {
            image: Product2,
            title: "Fast Gas 580g N2O",
            price: "499 kr",
            reduce: "",
            sale: ""
        },
        {
            image: Product3,
            title: "Fast Gas 3 Pack!",
            price: "999kr",
            reduce: "1299kr",
            sale: "50%"
        },
        {
            image: Product4,
            title: "Fast Gas 3 Pack!",
            price: "999kr",
            reduce: "1299kr",
            sale: "50%"
        },
        {
            image: Product1,
            title: "Fast Gas 6 Pack!",
            price: "1.799 kr",
            reduce: "2.499 kr",
            sale: "50%"
        },
        {
            image: Product1,
            title: "Fast Gas 580g N2O",
            price: "499 kr",
            reduce: "",
            sale: ""
        },
        {
            image: Product1,
            title: "Fast Gas 580g N2O",
            price: "499 kr",
            reduce: "",
            sale: ""
        },
        {
            image: Product2,
            title: "Fast Gas 580g N2O",
            price: "499 kr",
            reduce: "",
            sale: ""
        }
    ]

    return (
        <Box className={classes.productSection}>
            <Container maxWidth="xl">
                <Grid container spacing={3}>
                    <Grid item xs={12} className={classNames(classes.titleSection, classes.zIndex1)}>
                        <Typography variant="h3" fontWeight={700} align="center">
                            ALL PRODUCT
                        </Typography>
                        <Typography variant="body1" align="center">
                            Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Aliquet.
                        </Typography>
                    </Grid>
                    {
                        products.map((productItem, index) => (
                            <Grid item lg={3} md={3} sm={6} xs={6} key={index}>
                                <ProductItem product={productItem}/>
                            </Grid> 
                        ))
                    }
                    <Grid item xs={12}>
                        <Box mt={4} textAlign="center" className={classes.seeAllProduct}>
                            <Button variant="contained" color="green">
                                See All Product
                                <ChevronRightIcon />
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default AllProductsSection
