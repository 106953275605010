const customModalStyle = theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: "#0F1317",
    width: "800px",
    padding: "1.5rem 0",
    [theme.breakpoints.down('sm')]: {
      width: "90vw"
    }
  },
  modalHeader: {
    position: "relative",
    "& h5": {
      color: theme.palette.green.main,
      fontSize: "1.5rem",
      [theme.breakpoints.down('xs')]: {
        fontSize: "20px"
      }
    },
    [theme.breakpoints.down('sm')]: {
      padding: "1.5rem 0"
    }
  },
  closeBtn: {
    position: "absolute",
    top: "0",    
    color: "white",
    right: "24px"
  },
  modalContents: {
    maxHeight: "70vh",
    [theme.breakpoints.down('sm')]: {
      height: "50vh",      
      "& .MuiContainer-root": {
        padding: "0 1rem"
      }       
    },
    overflowY: "auto",    
    "&::-webkit-scrollbar": {
      height: "4px",
      width: "4px"
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      background: "#151B20"
    }
  }
})

export default customModalStyle
