import React from 'react'
import { Switch, Route } from "react-router-dom"

import { Box } from '@material-ui/core';

import MarketNavbar from "components/Navbars/MarketNavbar"
import MarketFooter from "components/Footer/MarketFooter"

import routes from "routes.js";

export default function Market() {

    const getRoutes = routes => {
        return routes.map((prop, key) => {
          if (prop.collapse) {
            return getRoutes(prop.views);
          }
          if (prop.layout === "/market") {
            return (
              <Route
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
              />
            );
          } else {
            return null;
          }
        });
      };

    return (
        <Box>
          <MarketNavbar />
          <Switch>
            {getRoutes(routes)}
          </Switch>
          <MarketFooter />
        </Box>
    )
}
