import Landing from "views/Market/Landing"
import AllProduct from "views/Market/AllProduct"
import ProductDetail from "views/Market/ProductDetail"
import Login from "views/Market/Login"
import Register from "views/Market/Register"
import ForgotPassword from "views/Market/ForgotPassword"
import TermsOfPurchase from "views/Market/TermsOfPurchase"
import Cart from "views/Market/Cart"
import Checkout from "views/Market/Checkout"

var routes = [
    {
        path: "/auth/login",
        name: "Login",
        component: Login,
        layout: "/market"
    },
    {
        path: "/auth/register",
        name: "Register",
        component: Register,
        layout: "/market"
    },
    {
        path: "/auth/forgot-password",
        name: "ForgotPassword",
        component: ForgotPassword,
        layout: "/market"
    },
    {
        path: "/terms-of-purchase",
        name: "Terms Of Purchase",
        component: TermsOfPurchase,
        layout: "/market"
    },
    {
        path: "/all-product",
        name: "All Product",
        component: AllProduct,
        layout: "/market"
    },
    {
        path: "/product-detail",
        name: "Product Detail",
        component: ProductDetail,
        layout: "/market"
    },
    {
        path: "/cart",
        name: "Cart",
        component: Cart,
        layout: "/market"
    },
    {
        path: "/checkout",
        name: "Checkout",
        component: Checkout,
        layout: "/market"
    },
    {
        path: "/",
        name: "Landing",
        component: Landing,
        layout: "/market"
    }
]

export default routes
