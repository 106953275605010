import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import RadioGroup from '@mui/material/RadioGroup'
import FormControl from '@mui/material/FormControl'

import Breadcrumb from "components/Breadcrumb"
import RadioCard from "components/RadioCard"
import BillingInformation from "./components/BillingInformation"
import OrderListSection from "./components/OrderListSection"

import SelectAddressModal from "./components/SelectAddressModal"
import ReceivedOrderModal from "./components/ReceivedOrderModal"

import styles from "assets/jss/views/checkoutViewStyle"

const useStyles = makeStyles(styles)

const Checkout = () => {

  const classes = useStyles()

  const [method, setShippingMethod] = useState("200 kr")

  const [openAddressModal, setOpenAddressModal] = useState(false)  
  const [openReceivedModal, setOpenReceivedModal] = useState(false)

  const handleChangeMethod = (e) => {
    setShippingMethod(e.target.value)
  }

  const shippingMethod = [
    {
      option: "200 kr",
      description: "Exit Hässelby Spånga Botkyrka Danderyd Täby Nacka Lidingö and other surrounding municipalities"
    },
    {
      option: "150 kr",
      description: "Exit Stockholm Solna Sundbyberg Bromma Älvsjö"
    },
    {
      option: "0 kr",
      description: "BEFORE CUSTOMS DUTY Order before 18:00 ONLY PRE-ORDER DO NOT USE BETWEEN 18:00 - 05:00"
    }
  ]

  return (
    <Box className={classes.wrapper}>
      <Hidden smDown>
        <Breadcrumb breadcrumb={["Home", "Cart", "Checkout"]}/>
      </Hidden>
      <Box className={classes.contentSection}>          
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h3">
                Shipping Method
              </Typography>
            </Grid>
            <Grid item lg={8} md={8} sm={12} xs={12}>
              <Box className={classes.shippingSection}>
                <FormControl component="fieldset">
                  <RadioGroup name="shipping-method" value={method} onChange={handleChangeMethod}>
                    <Container maxWidth="xl">
                      <Grid container className={classes.methodContainer}>
                        {
                          shippingMethod.map((item, index) => (
                            <Grid item lg={4} md={4} sm={12} xs={12} key={index} className={classes.methodItem}>
                              <RadioCard option={item} selected={method===item.option} />
                            </Grid>
                          ))
                        }
                      </Grid>
                    </Container>
                  </RadioGroup>
                </FormControl>
                <Box display="flex" justifyContent="space-between" className={classes.shippingFooter}>
                  <Typography variant="body1">
                    Shipping Cost
                  </Typography>
                  <Typography variant="body1">
                    { method }
                  </Typography>
                </Box>
              </Box>                
              <BillingInformation changeAddress={() => setOpenAddressModal(true)} />
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <OrderListSection receivedOrder={() => setOpenReceivedModal(true)} />
            </Grid>
          </Grid>
        </Container>        
      </Box>
      <SelectAddressModal 
        open={ openAddressModal }
        closeModal={ () => setOpenAddressModal(false) }
      />
      <ReceivedOrderModal 
        open={ openReceivedModal }
        closeModal={ () => setOpenReceivedModal(false) }
      />
    </Box>
  )
}

export default Checkout
