import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import DeleteIcon from '@material-ui/icons/Delete'

import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import Button from "components/CustomForms/Button"

import styles from "assets/jss/components/cartItemStyle"

const useStyles = makeStyles(styles)

const CartItem = (props) => {

  const classes = useStyles()

  const { cartItem } = props

  const [count, setCount] = useState()

  useEffect(() => {
    setCount(cartItem.quantity)
  }, [cartItem])

  return (
    <Box className={classes.cartItem + " " + "cartItem"} display="flex">
      <Box className={classes.product} display="flex">
        <Hidden smDown>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Hidden>        
        <img src={cartItem.productImg} alt="Product" />
        <Typography variant="body1">
          { cartItem.title }
        </Typography>
        <Hidden mdUp>
          <IconButton className={classes.deleteBtn}>
            <DeleteIcon />
          </IconButton>
        </Hidden>
      </Box>
      <Box className={classes.price} display="flex" alignItems="center" justifyContent="space-between">
        <Hidden mdUp>
          <Typography variant="body1">
            Price
          </Typography>
        </Hidden>
        <Typography variant="body1">
          { `${ new Intl.NumberFormat().format(cartItem.price) } kr` }
        </Typography>
      </Box>
      <Box className={classes.quantity} display="flex" alignItems="center">
        <Hidden mdUp>
          <Typography variant="body1" className={classes.fieldTitle}>
            Quantity
          </Typography>
        </Hidden>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Button 
            variant="contained"
            className={classNames({[classes.disableBtn]: count === 1})}
            color="green" 
            onClick={() => { count > 1 && setCount(count - 1) }}
          >
            -
          </Button>
          <Typography variant="body1">
            { count }
          </Typography>
          <Button variant="contained" color="green" onClick={() => setCount(count + 1)}>
            +
          </Button>
        </Box>
           
      </Box>
      <Box className={classes.total} display="flex" alignItems="center" justifyContent="space-between">
        <Hidden mdUp>
          <Typography variant="body1" className={classes.fieldTitle}>
            Subtotal
          </Typography>
        </Hidden>
        <Typography variant="body1">
          { `${ new Intl.NumberFormat().format(count * cartItem.price) } kr` }
        </Typography>
      </Box>
    </Box>
  )
}

export default CartItem
