import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Button from "components/CustomForms/Button"

import Breadcrumb from "components/Breadcrumb"
import ProductItem from "components/ProductItem"

import styles from "assets/jss/views/productDetailViewStyle"
import Product1 from "assets/img/product-1.png"
import Product2 from "assets/img/product-2.png"
import Product3 from "assets/img/product-3.png"
import Product4 from "assets/img/product-4.png"
import Product from "assets/img/product detail/product.png"
const useStyles = makeStyles(styles)

const ProductDetail = () => {

	const classes = useStyles() 
	
	const [productImg, setProductImg] = useState(Product)
	const [count, setCount] = useState(1)
	const [price, setPrice] = useState(1799)

	const products = [
		{
				image: Product1,
				title: "Fast Gas 580g N2O",
				price: "499 kr",
				reduce: "",
				sale: ""
		},
		{
				image: Product2,
				title: "Fast Gas 580g N2O",
				price: "499 kr",
				reduce: "",
				sale: ""
		},
		{
				image: Product3,
				title: "Fast Gas 3 Pack!",
				price: "999kr",
				reduce: "1299kr",
				sale: "50%"
		},
		{
				image: Product4,
				title: "Fast Gas 3 Pack!",
				price: "999kr",
				reduce: "1299kr",
				sale: "50%"
		}				
	]

	return (
		<Box className={classes.wrapper}>
			<Breadcrumb breadcrumb={["Home", "Cas Container", "Fast Gas 6 Pack!"]}/>

			<Box className={classes.detailSection}>
				<Container maxWidth="xl">
					<Grid container spacing={3}>
						<Grid item lg={6} md={6} sm={12} xs={12}>
							<Box className={classes.productImg} textAlign="center">
								<img src={productImg} alt="Product" />
							</Box>
							<Box display="flex" className={classes.imageList} justifyContent="space-between">
								<img src={Product1} alt="Product" onClick={() => setProductImg(Product1)} className={classNames({[classes.activeGallery]: productImg === Product1})}/>
								<img src={Product2} alt="Product" onClick={() => setProductImg(Product2)} className={classNames({[classes.activeGallery]: productImg === Product2})}/>
								<img src={Product3} alt="Product" onClick={() => setProductImg(Product3)} className={classNames({[classes.activeGallery]: productImg === Product3})}/>
							</Box>
						</Grid>
						<Grid item lg={6} md={6} sm={12} xs={12}>
							<Box className={classes.productCard}>
								<Typography variant="h3" className={classes.productTitle}>
									Fast Gas 6 Pack!
								</Typography>
								<Typography variant="body1" className={classes.discount}>
									{`${new Intl.NumberFormat().format(2499)} kr`}
								</Typography>
								<Box position="relative" className={classes.priceSection}>
									<Typography variant="body1" className={classes.price}>
										{new Intl.NumberFormat().format(price)}
									</Typography>
									<Typography variant="body1" className={classes.currency}>
										kr
									</Typography>
								</Box>								
								<Typography variant="body1" className={classes.detail}>
									580g reliable tube for high production rate
									6 large tubes of N2O in a carton that together correspond to 432 8g cartridges 
									and are easily suitable for high capacity in production such as larger events or 
									parties where a lot of cream is consumed. 
									Best suited for Catering or Commercial Kitchen.
								</Typography>
								<Box>
									<Container className={classes.counterSection}>
										<Grid container>
											<Grid item xs={6}>
												<Box>
													<Typography variant="body1" className={classes.label}>
														Quantity
													</Typography>
													<Box display="flex">
														<Button variant="contained" color="green" className={classes.counterBtn} onClick={() => {count> 0 && setCount(count-1)}}>
															-
														</Button>
														<Box className={classes.count} display="flex" alignItems="center" justifyContent="center">
															<Typography variant="body1" align="center">
																{count}
															</Typography>
														</Box>														
														<Button variant="contained" color="green" className={classes.counterBtn} onClick={() => setCount(count+1)}>
															+
														</Button>
													</Box>													
												</Box>
											</Grid>
											<Grid item xs={6}>
												<Box>
													<Typography variant="body1" className={classes.label}>
															Subtotal
													</Typography>
													<Typography variant="body1" className={classes.subTotal}>
														{`${new Intl.NumberFormat().format(price*count)} kr`}
													</Typography>
												</Box>
											</Grid>
										</Grid>
									</Container>
								</Box>
								<Box>
									<Button variant="contained" color="green" fullWidth>
										Add to Cart
									</Button>
								</Box>
							</Box>
						</Grid>
					</Grid>
				</Container>
			</Box>

			<Box className={classes.relatedProducts}>
				<Container maxWidth="xl">
					<Grid container spacing={3}>
						<Grid item xs={12} className={classes.titleSection}>
							<Typography variant="h3" fontWeight={700} align="center">
								more related product
							</Typography>							
						</Grid>
						<Container maxWidth="xl">
							<Grid container spacing={3} className={classes.productList}>
								{
									products.map((productItem, index) => (
										<Grid item lg={3} md={3} sm={6} xs={6} key={index}>
												<ProductItem product={productItem}/>
										</Grid> 
									))
								}		
							</Grid>
						</Container>						
					</Grid>
				</Container>
			</Box>
		</Box>
	)
}

export default ProductDetail
